@font-face {
  font-family: 'KievitOT';
  src: local('KievitOT'), url(./KievitOT-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'KievitOT';
  src: local('KievitOT'), url(./KievitOT-Thin.ttf) format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'KievitOT';
  src: local('KievitOT'), url(./KievitOT-ExtraLight.ttf) format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'KievitOT';
  src: local('KievitOT'), url(./KievitOT-Light.ttf) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'KievitOT';
  src: local('KievitOT'), url(./KievitOT-Medium.ttf) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'KievitOT';
  src: local('KievitOT'), url(./KievitOT-SemiBold.ttf) format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'KievitOT';
  src: local('KievitOT'), url(./KievitOT-Bold.ttf) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'KievitOT';
  src: local('KievitOT'), url(./KievitOT-ExtraBold.ttf) format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'KievitOT';
  src: local('KievitOT'), url(./KievitOT-Black.ttf) format('truetype');
  font-weight: 900;
}
